<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="6">
        <CCard class="p-4">
          <CCardBody>
            <h2 v-if="processing">Please wait...</h2>
            <h2 v-if="error" class="text-danger">
              Error during social login:<br /><br />{{ error }}
            </h2>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { apiUrl } from "../../http";
export default {
  name: "SocialLoginHandler",
  data() {
    return {
      processing: true,
      error: null,
    };
  },

  mounted() {
    const provider = this.$route.params.type;
    if (provider === "facebook") {
      const code = this.$route.query.code;
      const error = this.$route.query.error;
      const error_code = this.$route.query.error_code;
      if (error || error_code) {
        this.processing = false;
        this.error = error;
      } else if (code) {
        this.socialAuth(provider);
      }
    } else if (provider === "google") {
      this.socialAuth(provider);
    } else {
      this.processing = false;
      this.error = "Provider not supported";
    }
  },

  methods: {
    socialAuth(provider) {
      const self = this;
      const url = new URL(window.location);
      const process = this.$auth.user() ? "&process=connect" : "";
      this.axios
        .post(apiUrl(`/api/account/social/${url.search}${process}`), {
          redirectUrl: `${url.origin}${url.pathname}`,
          provider,
        })
        .then((response) => {
          self.loginUserByTokenAndRedirect(response.data.payload.token);
        })
        .catch((error) => {
          console.error(error);
          this.processing = false;
          this.error = error.response.status;
        });
    },

    loginUserByTokenAndRedirect(token) {
      const self = this;
      this.$auth.token(null, token, false);
      this.$auth
        .refresh()
        .then(() => {
          return this.$auth.fetch();
        })
        .then(() => {
          self.$router.push({ path: "/" });
        })
        .catch((error) => {
          console.error(error.response);
          self.processing = false;
          self.error = error.response.status;
        });
    },
  },
};
</script>
